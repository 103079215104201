<template>
  <VCard id="suggestion-edit">
    <div class="top-sticky white">
      <div class="d-flex justify-space-between align-center pa-3">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn
          icon
          @click="close"
        >
          <VIcon
            color="blue-grey darken-3"
            v-text="'mdi-close'"
          />
        </VBtn>
      </div>
      <VDivider />
    </div>
    <VForm
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <VRow class="mx-0">
        <VCol class="py-6 pl-6 pr-10">
          <WideTextField
            label="Название Upsell"
            :value="suggestionToEdit.title"
            @input="suggestionToEdit.title = $event"
          />
          <div class="section-title mt-10">
            Условия
          </div>
          <VDivider class="my-2" />
          <div>
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="subtitle-2 font-weight-bold text--secondary">
                Продвигать это
              </div>
              <Dropdown
                :height="labels.length > 5 ? '288' : null"
                is-icon
                :items="labelsToOffer"
                no-header
                search-placeholder="Поиск меток"
                searchable
                :selected="suggestionToEdit.labelsToOffer"
                @selected="suggestionToEdit.labelsToOffer = $event"
              />
            </div>
            <div
              v-if="!suggestionToEdit.labelsToOffer.length"
              class="grey-box"
            >
              Выберите метки блюд
            </div>
            <ChipGroup
              v-else
              :items="suggestionToEdit.labelsToOffer"
              :max="7"
              @remove="remove('labelsToOffer', $event)"
            />
          </div>
          <VDivider class="mt-5" />
          <VRow class="mx-0">
            <VCol class="px-0">
              <div class="d-flex justify-space-between align-center mb-2">
                <div class="subtitle-2 font-weight-bold text--secondary">
                  Если в корзине есть
                </div>
                <Dropdown
                  :height="labels.length > 5 ? '288' : null"
                  is-icon
                  :items="labelsToInclude"
                  no-header
                  search-placeholder="Поиск меток"
                  searchable
                  :selected="suggestionToEdit.labelsToInclude"
                  @selected="suggestionToEdit.labelsToInclude = $event"
                />
              </div>
              <div
                v-if="!suggestionToEdit.labelsToInclude.length"
                class="grey-box"
              >
                Добавьте метки блюд
              </div>
              <ChipGroup
                v-else
                :items="suggestionToEdit.labelsToInclude"
                :max="7"
                type="success"
                @remove="remove('labelsToInclude', $event)"
              />
              <VDivider class="mt-5" />
            </VCol>
            <VCol
              class="d-flex justify-center pt-12"
              cols="1"
            >
              <VImg
                class="mt-2"
                max-height="24"
                max-width="24"
                :src="require('@/assets/icons/connect.svg')"
              />
            </VCol>
            <VCol class="px-0">
              <div class="d-flex justify-space-between align-center mb-2">
                <div class="subtitle-2 font-weight-bold text--secondary">
                  Не продвигать если в корзине есть это
                </div>
                <Dropdown
                  :height="labels.length > 5 ? '288' : null"
                  is-icon
                  :items="labelsToExclude"
                  no-header
                  search-placeholder="Поиск меток"
                  searchable
                  :selected="suggestionToEdit.labelsToExclude"
                  @selected="suggestionToEdit.labelsToExclude = $event"
                />
              </div>
              <div
                v-if="!suggestionToEdit.labelsToExclude.length"
                class="grey-box"
              >
                Добавьте метки блюд
              </div>
              <ChipGroup
                v-else
                :items="suggestionToEdit.labelsToExclude"
                :max="7"
                type="error"
                @remove="remove('labelsToExclude', $event)"
              />
              <VDivider class="mt-5" />
            </VCol>
          </VRow>
          <div class="section-title mt-10">
            Позиция продвижения Upsell кампании
          </div>
          <VDivider class="my-2" />
          <div class="mb-12">
            <VRadioGroup v-model="suggestionToEdit.position">
              <VRadio
                v-for="(position, index) in positions"
                :key="index"
                class="subtitle-2 black-label light-grey-checkbox"
                color="red"
                :label="position.title"
                off-icon="mdi-checkbox-blank-outline"
                on-icon="mdi-checkbox-marked"
                :ripple="false"
                :value="position.value"
              />
            </VRadioGroup>
          </div>
        </VCol>
        <VDivider vertical />
        <VCol class="pa-4 d-flex flex-column" cols="3">
          <div>
            <div class="font-weight-bold mb-2">
              Статус
            </div>
            <VMenu
              v-if="suggestionToEdit.isDeleted"
              content-class="elevation-1"
              :disabled="!suggestionToEdit.id"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn
                  v-if="suggestionToEdit.isDeleted"
                  v-bind="attrs"
                  block
                  class="elevation-0 d-flex justify-space-between pl-1 pr-2"
                  :color="stateParams.removed.color"
                  :style="{'color': stateParams.removed.textColor}"
                  v-on="on"
                >
                  <div class="subtitle-1">
                    <VIcon v-text="'mdi-circle-small'" />
                    {{ stateParams.removed.title }}
                  </div>
                  <VIcon
                    size="18"
                    v-text="'mdi-chevron-down'"
                  />
                </VBtn>
              </template>
              <VList>
                <VListItemGroup v-model="suggestionToEdit.isDeleted">
                  <VListItem
                    dense
                    :value="false"
                    @click="suggestionToEdit.state = 'enabled'"
                  >
                    <VListItemTitle
                      class="subtitle-1 pl-6"
                      v-text="'Активно'"
                    />
                  </VListItem>
                </VListItemGroup>
              </VList>
            </VMenu>
            <VMenu
              v-else
              content-class="elevation-1"
              :disabled="!suggestionToEdit.id"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  block
                  class="elevation-0 d-flex justify-space-between pl-1 pr-2"
                  :color="stateParams[suggestionToEdit.state].color"
                  :style="{'color': stateParams[suggestionToEdit.state].textColor}"
                  v-on="on"
                >
                  <div class="subtitle-1">
                    <VIcon v-text="'mdi-circle-small'" />
                    {{ stateParams[suggestionToEdit.state].title }}
                  </div>
                  <VIcon
                    v-if="suggestionToEdit.id"
                    size="18"
                    v-text="'mdi-chevron-down'"
                  />
                </VBtn>
              </template>
              <VList>
                <VListItemGroup v-model="suggestionToEdit.state">
                  <VListItem
                    v-for="(state, index) in states"
                    v-show="state !== suggestionToEdit.state"
                    :key="index"
                    dense
                    :value="state"
                  >
                    <VListItemTitle
                      class="subtitle-1 pl-6"
                      v-text="stateParams[state].title"
                    />
                  </VListItem>
                </VListItemGroup>
              </VList>
            </VMenu>
          </div>
          <VDivider class="mt-4 mb-4" />
          <div>
            <div class="font-weight-bold mb-2">
              Дата кампании
            </div>
            <DateTimePicker
              :date-end="suggestionToEdit.expirationDate"
              :date-start="suggestionToEdit.startingDate"
              @date-end="suggestionToEdit.expirationDate = $event"
              @date-start="suggestionToEdit.startingDate = $event"
            />
          </div>
          <VDivider class="my-4" />
          <div>
            <div class="d-flex justify-space-between align-center">
              <div
                id="platforms"
                class="font-weight-bold mb-2"
              >
                Платформы
              </div>
              <Dropdown
                attach="#platforms"
                is-icon
                :items="targets"
                :selected="suggestionToEdit.targets"
                title="Все платформы"
                @selected="suggestionToEdit.targets = $event"
              />
            </div>
            <div
              v-if="!suggestionToEdit.targets.length"
              class="subtitle-1 px-2"
            >
              Укажите платформы
            </div>
            <ChipGroup
              v-else
              :items="suggestionToEdit.targets"
              @remove="remove('targets', $event)"
            />
          </div>
          <VDivider class="my-4" />
          <div>
            <div class="d-flex font-weight-bold justify-space-between">
              <VInput
                v-model="suggestionToEdit.cities"
                hide-details="auto"
              >
                Регионы
              </VInput>
              <Dropdown
                height="288"
                is-icon
                :items="cities"
                search-placeholder="Поиск регионов"
                searchable
                :selected="suggestionToEdit.cities"
                title="Все регионы"
                @selected="suggestionToEdit.cities = $event"
              />
            </div>
            <div
              v-if="!(suggestionToEdit.cities && suggestionToEdit.cities.length)"
              class="subtitle-1 px-2"
            >
              Укажите регионы
            </div>
            <ChipGroup
              v-else
              :items="suggestionToEdit.cities"
              :max="6"
              @remove="remove('cities', $event)"
            />
          </div>
          <VDivider class="my-4" />
          <div>
            <div class="d-flex font-weight-bold justify-space-between">
              <VInput
                v-model="suggestionToEdit.hubs"
                hide-details="auto"
              >
                Хабы
              </VInput>
              <Dropdown
                height="288"
                is-icon
                :items="hubs"
                search-placeholder="Поиск хабов"
                searchable
                :selected="suggestionToEdit.hubs"
                title="Все хабы"
                @selected="suggestionToEdit.hubs = $event"
              />
            </div>
            <div
              v-if="!suggestionToEdit.hubs.length"
              class="subtitle-1 px-2"
            >
              Укажите хабы
            </div>
            <ChipGroup
              v-else
              :items="suggestionToEdit.hubs"
              :max="6"
              @remove="remove('hubs', $event)"
            />
          </div>
          <VDivider class="my-4" />
          <div>
            <div class="d-flex font-weight-bold justify-space-between">
              <VInput
                v-model="suggestionToEdit.restaurants"
                hide-details="auto"
              >
                Заведения
              </VInput>
              <DropdownWithPagination
                :current-page="restaurants.currentPage"
                :items="restaurants.dataShort || []"
                :loading="restaurantsAreLoading"
                offset-y
                search-placeholder="Поиск заведений"
                :selected="suggestionToEdit.restaurants"
                title="Все заведения"
                :total-page="restaurants.totalPages"
                @page="getRestaurants($event.search, $event.page)"
                @search="getRestaurants"
                @selected="suggestionToEdit.restaurants = $event"
              />
            </div>
            <div
              v-if="!suggestionToEdit.restaurants.length"
              class="subtitle-1 px-2"
            >
              Выберите заведения
            </div>
            <ChipGroup
              v-else
              :items="suggestionToEdit.restaurants"
              :max="6"
              @remove="remove('restaurants', $event)"
            />
          </div>
          <VDivider class="my-4" />
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky">
      <VDivider />
      <div
        class="d-flex align-center grey lighten-5 px-4 py-3"
        :class="(suggestionToEdit.id || loading) ? 'justify-space-between' : 'justify-end'"
      >
        <div>
          <RotatingIcon
            v-if="loading"
            text="Загружается..."
          />
          <span
            v-if="suggestionToEdit.id && !loading"
            class="text--secondary"
          >
            {{ bottomDate }}
          </span>
        </div>
        <div class="subtitle-2">
          <VBtn
            id="save-btn"
            class="subtitle-2 br-8 white--text font-weight-bold blue-grey-disabled--text"
            color="#E43539"
            :disabled="!valid"
            elevation="0"
            height="40"
            @click="save"
          >
            {{ suggestionToEdit.id ? 'Сохранить и закрыть' : 'Создать и активировать' }}
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import {
  fetchHubRestaurants, createSuggestion, updateSuggestion,
} from '@/api/api';
import { suggestionStates } from '@/conditions/states';
import { formatDateWithTime } from '@/helpers/date';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import WideTextField from '@/components/reusable/WideTextField.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';
import DropdownWithPagination from '@/components/reusable/DropdownList.vue';
import DateTimePicker from '@/components/reusable/DateTimePicker.vue';
import ChipGroup from '@/components/reusable/ChipGroup.vue';
import RotatingIcon from '@/components/reusable/RotatingIcon.vue';

export default {
  components: {
    RotatingIcon,
    Breadcrumbs,
    WideTextField,
    Dropdown,
    DateTimePicker,
    ChipGroup,
    DropdownWithPagination,
  },
  props: {
    suggestion: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Array,
      default: () => [],
    },
    hubs: {
      type: Array,
      default: () => [],
    },
    targets: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cities: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update', 'close'],
  data() {
    return {
      suggestionToEdit: {
        title: '',
        state: 'enabled',
        position: 0,
        startingDate: null,
        expirationDate: null,
        targets: [],
        hubs: [],
        restaurants: [],
        labelsToOffer: [],
        labelsToInclude: [],
        labelsToExclude: [],
        cities: [],
      },
      restaurants: {},
      stateParams: suggestionStates,
      states: ['enabled', 'disabled'],
      positions: [{ title: 'Любая', value: 0 }, { title: 'Топ 2', value: 1000 }],
      formatDate: formatDateWithTime,
      valid: false,
      restaurantsAreLoading: false,
    };
  },
  created() {
    if (Object.keys(this.suggestion).length) {
      this.suggestionToEdit = { ...JSON.parse(JSON.stringify(this.suggestion)) };
    }
  },
  mounted() {
    this.getRestaurants();
  },
  computed: {
    breadcrumbs() {
      return [
        { text: 'Upsell', disabled: false, href: '/upsell' },
        {
          text: this.suggestion && this.suggestion.id ? `Upsell ID-${this.suggestionToEdit.id}` : 'Создать новый Upsell',
          disabled: true,
          href: '',
        },
      ];
    },
    labelsToOffer() {
      return this.labels.filter((label) => !this.isSelected('labelsToInclude', label.id)
        && !this.isSelected('labelsToExclude', label.id));
    },
    labelsToInclude() {
      return this.labels.filter((label) => !this.isSelected('labelsToOffer', label.id)
        && !this.isSelected('labelsToExclude', label.id));
    },
    labelsToExclude() {
      return this.labels.filter((label) => !this.isSelected('labelsToInclude', label.id)
        && !this.isSelected('labelsToOffer', label.id));
    },
    bottomDate() {
      if (this.suggestionToEdit.updatedAt) {
        return `Изменено: ${this.formatDate(this.suggestionToEdit.updatedAt)}`;
      }
      return `Создано: ${this.formatDate(this.suggestionToEdit.createdAt)}`;
    },
  },
  methods: {
    getRestaurants(search, page = 1) {
      const params = { page, limit: 50 };
      if (search && search.length > 0) {
        params.title = search;
      }
      this.restaurantsAreLoading = true;
      fetchHubRestaurants(params).then((response) => {
        this.restaurants = response;
        this.restaurants.currentPage = (response.data && response.data.length > 0)
          ? response.currentPage
          : 0;
      }).finally(() => {
        this.restaurantsAreLoading = false;
      });
    },
    isSelected(key, id) {
      return this.suggestionToEdit[key].some((element) => element.id === id);
    },
    remove(key, index) {
      if (index >= 0) {
        this.suggestionToEdit[key].splice(index, 1);
      }
    },
    update(text) {
      this.$emit('update', text);
    },
    close() {
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.suggestionToEdit.id) {
          updateSuggestion(this.suggestionToEdit).then(() => {
            this.update(`${this.suggestionToEdit.title} обновлен`);
          });
        } else {
          createSuggestion(this.suggestionToEdit).then(() => {
            this.update(`${this.suggestionToEdit.title} создан`);
          });
        }
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
