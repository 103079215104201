<template>
  <div>
    <div class="pt-5 px-8">
      <PageHeader
        create-btn-title="Создать новый Upsell"
        page-title="Upsell"
        :search-items="suggestionsForSearch"
        @click:create="edit({})"
        @search="search = $event"
        @search-input="searchInput = $event"
      />
      <VDialog
        v-model="suggestionDialog"
        content-class="br-12"
        max-width="1248"
      >
        <Suggestion
          :key="key"
          :cities="cities"
          :hubs="hubs"
          :labels="labels"
          :loading="suggestionParamsLoading"
          :suggestion="suggestionToEdit"
          :targets="targets"
          @close="suggestionDialog = false"
          @update="update"
        />
      </VDialog>
      <VDivider />
      <div class="d-flex my-4">
        <Dropdown
          height="420"
          :items="hubs"
          searchable
          :selected="selectedHubs"
          title="Все хабы"
          @selected="selectedHubs = $event"
        />
        <Dropdown
          :items="states"
          :selected="selectedStates"
          title="Все статусы"
          @selected="selectedStates = $event"
        />
        <Dropdown
          height="420"
          :items="cities"
          searchable
          :selected="selectedCities"
          title="Все регионы"
          @selected="selectedCities = $event"
        />
        <VBtn
          class="dropdown subtitle-2 elevation-0"
          :class="showExpired ? 'reddish' : 'greyish'"
          @click="showExpired = !showExpired"
        >
          Показать истекшие
        </VBtn>
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="suggestionsHeaders(hubs.length || 0)"
      :items="suggestions"
      :page="currentPage"
      @deactivate="deactivateSuggestion"
      @delete="deleteSuggestion"
      @edit="edit"
      @update-page="currentPage = $event"
      @update-sort="selectedSort = $event"
    />
    <SnackbarsSelection :items="snackbars" />
  </div>
</template>

<script>
import {
  deleteSuggestion,
  fetchCityList,
  fetchFoodLabels,
  fetchHubs,
  fetchSuggestionById,
  fetchSuggestions,
  getTargetList,
  updateSuggestion,
} from '@/api/api';
import { suggestionsHeaders } from '@/conditions/tablesHeaders';
import { itemsPerPage } from '@/helpers/table';
import PageHeader from '@/components/reusable/PageHeader.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';
import Suggestion from '@/components/Suggestion.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import SnackbarsSelection from '@/components/reusable/SnackbarsSelection.vue';

const defaultStates = [{ title: 'Активно', value: 'enabled' }, { title: 'Отключено', value: 'disabled' }];

export default {
  components: {
    PageHeader, Dropdown, Suggestion, UniversalTable, SnackbarsSelection,
  },
  data() {
    return {
      suggestionToEdit: {},
      suggestionDialog: false,
      suggestionsHeaders,
      suggestions: [],
      suggestionsForSearch: [],
      states: defaultStates,
      selectedStates: defaultStates,
      hubs: [],
      selectedHubs: [],
      currentPage: 1,
      key: 0,
      search: null,
      searchInput: null,
      labels: [],
      targets: [],
      timeout: null,
      snackbarText: '',
      snackbars: [],
      wasPressedBefore: false,
      suggestionParamsLoading: false,
      showExpired: false,
      selectedSort: {},
      cities: [],
      selectedCities: [],
    };
  },
  mounted() {
    fetchHubs({ limit: 1000 }).then((response) => {
      this.hubs = response.data;
      this.selectedHubs = this.hubs;
    })
      .catch(() => this.getSuggestions());
    this.getCities();
  },
  computed: {
    isAllRegions() {
      return this.selectedHubs.length === this.hubs.length;
    },
    params() {
      const params = {
        limit: itemsPerPage,
      };
      if (this.selectedStates.length === 1) {
        params.statuses = this.selectedStates[0].value;
      }
      if (!this.isAllRegions) {
        params.hub_ids = this.selectedHubs.map((hub) => hub.id).join(',');
      }
      if (this.showExpired) {
        params.is_expired = true;
      }
      const selectedSortKeys = Object.keys(this.selectedSort);
      if (selectedSortKeys.length) {
        const sortBy = selectedSortKeys[0];
        params.sort_by = sortBy;
        params.sort_order = this.selectedSort[sortBy];
      }
      if (this.selectedCities.length) {
        params.city_ids = this.selectedCities.map((item) => item.pk).toString();
      }
      return params;
    },
  },
  methods: {
    edit(suggestion) {
      this.key += 1;
      this.suggestionToEdit = suggestion;
      this.suggestionDialog = true;
      if (!this.wasPressedBefore) {
        this.suggestionParamsLoading = true;
        getTargetList().then((response) => {
          this.targets = response;
        });
        fetchFoodLabels({ limit: 1000 }).then((response) => {
          this.labels = response.data;
          this.suggestionParamsLoading = false;
        });
        this.wasPressedBefore = true;
      }
      if (this.suggestionToEdit.cities && this.suggestionToEdit.cities.length) {
        const selectedCities = [];
        this.suggestionToEdit.cities.forEach((item) => {
          const city = this.cities.find((el) => el.pk === item.id.toString());
          if (city) {
            selectedCities.push(city);
          }
        });
        this.suggestionToEdit.cities = selectedCities;
      }
    },
    getSuggestions(page = 1) {
      this.$refs.table.loading = true;
      const params = this.params;
      params.page = page;
      fetchSuggestions(params).then((response) => {
        this.suggestions = response.results;
        this.currentPage = response.current_page;
        this.$refs.table.totalPage = response.last_page;
        this.$refs.table.totalCount = response.total;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    containsOnlyNumbers(value) {
      return /^\d+$/.test(value);
    },
    getSuggestionsForSearch(searchInput) {
      if (this.containsOnlyNumbers(searchInput)) {
        fetchSuggestionById(searchInput).then((response) => {
          this.suggestionsForSearch = [response];
        });
      } else {
        const params = { ...JSON.parse(JSON.stringify(this.params)) };
        params.title = searchInput;
        params.page = 1;
        fetchSuggestions(params).then((response) => {
          this.suggestionsForSearch = response.results;
        });
      }
    },
    deactivateSuggestion(suggestion) {
      // eslint-disable-next-line no-param-reassign
      suggestion.state = 'disabled';
      updateSuggestion(suggestion).then(() => {
        this.setSnackbar(`${suggestion.title} деактивирован`);
      });
    },
    deleteSuggestion(suggestion) {
      deleteSuggestion(suggestion.id).then(() => {
        this.getSuggestions(this.currentPage);
        this.setSnackbar(`${suggestion.title} удален`);
      });
    },
    setSnackbar(text) {
      const snackbar = {
        show: true,
        text: `Upsell ${text}`,
      };
      this.snackbars.push(snackbar);
    },
    async update(text) {
      await this.getSuggestions(this.currentPage);
      await this.setSnackbar(text);
    },
    getCities() {
      fetchCityList().then((res) => {
        this.cities = res;
      });
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.getSuggestions();
      },
    },
    searchInput(val) {
      if (val) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getSuggestionsForSearch(val);
        }, 500);
      } else {
        this.search = null;
        this.suggestionsForSearch = [];
      }
    },
    search(item) {
      if (item && item.id) {
        fetchSuggestionById(item.id).then((response) => {
          this.suggestions = [response];
          this.$refs.table.totalPage = 1;
          this.$refs.table.totalCount = 1;
        });
      } else {
        this.getSuggestions();
      }
    },
    currentPage(val) {
      this.getSuggestions(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .snackbar {
    position: relative;
    .v-snack__wrapper {
      border-radius: 8px;
      min-width: auto;
      position: relative;
    }
  }
}

.snackbar-div {
  position: fixed;
  z-index: 1000;
  right: 40px;
  bottom: 70px;
}
</style>
